$pxs: 0,5,10,15,20,25,30,40,50,75,100,150,200;

@each $i in $pxs{
	.margin-#{$i}{
		margin:#{$i}px;
	}
	.margin-top-#{$i}{
		margin-top:#{$i}px;
	}
	.margin-right-#{$i}{
		margin-right:#{$i}px;
	}
	.margin-bottom-#{$i}{
		margin-bottom:#{$i}px;
	}
	.margin-left-#{$i}{
		margin-left:#{$i}px;
	}
	.padding-#{$i}{
		padding:#{$i}px;
	}
	.padding-top-#{$i}{
		padding-top:#{$i}px;
	}
	.padding-right-#{$i}{
		padding-right:#{$i}px;
	}
	.padding-bottom-#{$i}{
		padding-bottom:#{$i}px;
	}
	.padding-left-#{$i}{
		padding-left:#{$i}px;
	}
	
}


@each $p in $pxs{
	.margin-top-minus-#{$p}{
		margin-top:-#{$p}px !important;
	}
	.margin-bottom-minus-#{$p}{
		margin-bottom:-#{$p}px !important;
	}
	.margin-left-minus-#{$p}{
		margin-left:-#{$p}px !important;
	}
	.margin-right-minus-#{$p}{
		margin-right:-#{$p}px !important;
	}
	.margin-minus-#{$p}{
		margin:-#{$p}px !important;
	}
}

$position: 1,2,3,4,5,6,7;

@each $p in $position{
	
	[class*="currency"]{
		&[class*="#{$p}"]{
			td:nth-child(#{$p}){
				text-align:right;
			}
		th:nth-child(#{$p}){
				text-align:right;
			}
		}
	}


}

