
[class^="dec-"],[class*=" dec-"] {
	border-top-width:4px;
	border-top-style:solid;
	border-top-color:#eee;
	clear:both;

	&[class$="-primary"],&[class*="-primary "] {
		border-top-color: $brand-primary;
	}
	&[class$="-info"],&[class*="-info "]{
		border-top-color:$brand-light-green;
	}
	&[class$="-dark"],&[class*="-dark "]{
		border-top-color:#333;
	}
	&[class$="-grey"],&[class*="-grey "]{
		border-top-color:#ddd;
	}
	&[class$="-midgrey"],&[class*="-midgrey "]{
		border-top-color:#ccc;
	}
	&[class$="-primary2"],&[class*="-primary2 "]{
		border-top-color:$brand-primary;
		border-top-width:2px;
	}
	&[class$="-warning"],&[class*="-warning "]{
		border-top-color:$brand-warning;
	}
	&[class$="-danger"],&[class*="-danger "]{
		border-top-color:$brand-danger;
	}
	&[class$="-top-2"],&[class*="-top-2 "]{
		border-top-width:2px;

	}
	&[class$="-top-1"],&[class*="-top-1 "]{
		border-top-width:1px;
	}

}



.dec-notice{
	border-top-width:4px;
	border-top-style:solid;
	border-top-color:#eee;
	border-bottom:2px solid #eee;
	padding:5px 0;
	&.dec-notice-info{
		border-top-color:$brand-light-green;
	}
	&.dec-notice-primary{
			border-top-color:$brand-primary;
		}
	&.dec-notice-primary2{
			border-top-color:$brand-primary;
			border-top-width:2px;
		}
	&.dec-notice-warning{
			border-top-color:$brand-warning;
		}
	&.dec-notice-danger{
			border-top-color:$brand-danger;
	}
	
}

