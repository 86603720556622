.cloudflare-domain-list {
    tr {
        &.netistrar-missing {
            color: grey;
            a { color: grey; }
        }
        &.cloud-flare-missing {
            color: red;
            a { color: red; }
        }
    }
}