.dropdown {
  > a, a:hover {
    text-decoration: none;
  }
}

.responsive-dropdown{
  width: 320px;
  @media screen and (min-width: 800px){
    width: 500px;

  }
  a {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}