tfoot.hide-inactive{
	display:none;
}


.cart-count {
	display:none;
}

.cart-active{
	tfoot.hide-inactive{
		display:table-footer-group;
	}
	.hide-active{
		display: none;
	}
	.cart-count {
		display:inline;
	}
}

#cart{
	span.category-des{
		font-weight:500;
		display: block;

	}
	.domain{
		display: block;
		font-weight:400;

		.tld{
			color: $brand-success;
		}
	}
	.package{

	}
	.registration-dates{
		@extend .small;
	}
	.inclusive{
		@extend .small;
	}
	
}

#cart{
	width:100%;
	margin-top:13px;
	thead{
		border-top:3px solid #bbb;
		border-bottom:1px solid #bbb;

		tr{
			td,th{
				position:relative;
				padding:.5em .7em;
				&:before, &:after{
					content:" ";
					left:-5px;
					border:6px solid #fff;
					position:absolute;
				}
				&:nth-child(2){
					text-align: right;
				}

			}
			th{
				border-left:1px dotted #aaa;
				font-weight:300;
				&:first-child{
					border-left:none;
				}
				&:before{
					top:1px;
					border:3px solid #fff;
				}
				&:after{
					bottom:0;
					border:3px solid #fff;
				}
				&:last-child{
					width:10%;
					text-align:center;
				}
			}
		}
	}
	tfoot{
		border-top:3px solid #bbb;
		border-bottom:1px solid #bbb;
		tr{
			th{

				text-align: right;
				padding:1em .7em;

				&:nth-child(2){
					font-size:2em;
					font-weight:400;
				}
			}
		}
	}
	tbody{
		tr{
			td{
				position:relative;
				padding:.5em .7em;
				border-left:1px dotted #aaa;
				border-top:1px dotted #aaa;
				font-weight:300;

				&:before, &:after{
					content:" ";
					left:-5px;
					border:6px solid #fff;
					position:absolute;
				}
				&:nth-child(2){
					text-align: right;
					font-weight:normal;
				}
				&:first-child{
					border-left:none;

				}
				&:before{
					top:1px;
					border:6px solid #fff;
				}
				&:after{
					bottom:0;
					border:6px solid #fff;
				}
				&:last-child{
					width:10%;
					text-align:center;
				}
			}
			&:first-child{
				td{
					border-top:none;
				}
			}

		}
	}


}


#checkout-footer{
	padding:.6em .5em;	
	color:#fff;
	background-color:$brand-primary;
	font-size:20px;
	text-align:right;
	position:fixed;
	bottom:0;
	width:100%;
	a{
		word-spacing: 0;
		color:#fff;
	}
	.glyphicons{
		line-height: 1.42857;
	}
}

#cart-summary{
	#item-label{
		.glyphicons{
			font-size:1.2em;
			margin-top:.2em;

		}
	}

	.item-label-count{
		font-size:1.5em;
	}
	
	#view-items-toggle-btn{
		display: block;
		border-top:1px solid #333;
		border-bottom:1px solid #333;
		line-height:2.5em;
		&:hover,&:focus{
			text-decoration: none;
		}
		.glyphicons{
			//height:2.5em;
			margin-top:.4em;
		}
	}

	.cart-items{
		width:100%;
		tr{
			td{
				text-align:right;
				border-bottom:1px dashed #aaa;
				padding-top:.8em;
				padding-bottom:.8em;
				&:first-child{
					width:90%;
					@extend .small;
					text-align: left;
					padding-right:10px;

				}
			}
		}
	}
	
	#payment-summary{
		table{
			width:100%;
			margin-top:.8em;
			tr{
				td{
					text-align: right;
					padding:.4em 0;
				}
				th{
					text-align: right;
					padding:.4em 0;
				}
			}
			
		}

	}
}

#payment-balance{
	.balance{
		font-size:1.5em;
	}
}

.payment-method-select{
	display:block;
	background-color: #eee;
	// background-color: lighten($brand-light-green,45%);
	padding:1em ;
	color:#555;


}

.ooacollapsible{
	.glyphicons-chevron-down{
		display: inline-block;
	}
	.glyphicons-chevron-right{
		display:none;
	}
	&.collapsed{
		.glyphicons-chevron-down{
			display:none;
		}
		.glyphicons-chevron-right{
			display:inline-block;
		}
	}
	a{
		&:hover{
			text-decoration: none;
		}
		margin-bottom:1em;
	}
}