.ticker-message {
  padding: .3em 0 .4em;
  border-bottom: 2px solid #ccc;
  margin-bottom: -1.0em;
}

.notice {
  padding: 1em;
  text-align: center;
  &.notice-dark {
    background-color: #333;
    color: #fff;
  }
  &.notice-warning {
    background-color: $brand-warning;
    color: #fff;
  }
  &.notice-top {
    margin: 0;
    border: 0;
  }
  a {
    color: #efefef;
    font-weight: bold;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

#saved-message, #action-message {
  position: fixed;
  top: 0;
  right: 0;
  padding: 1em;
  color: white;
  background-color: #333;
  z-index: 99999;
}

#action-required{
  background-color: $brand-danger;
  color:#fff;
  font-size:14px;
  padding:2px 0;
  a{
    border-bottom:1px dotted #ddd;
    color:#eee;

  }
}
