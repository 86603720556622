.subscription-features-container {
  display: -webkit-box;
  display: flex;
  .feature-descriptions {
    flex-grow: 1;
  }
  .feature-check {
    text-align: center;
    flex-grow: 0;
    position: relative;
    padding-bottom: 50px;
    margin: 0 5px;
    width: 100px;
    max-width: 200px;
    .btn {
      @extend .btn-block;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
