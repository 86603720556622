.strong-footer{
	border-top:4px solid $brand-primary;
	background-color:#ebebeb;
	padding:50px 0;

	ul{
		li{
			margin-bottom:.5em;
			a{
				color:$text-color;
				text-decoration: underline;
			}
		}
	}

	hr{
		border-top:2px solid #ccc;
	}
	h4{
		color:$headings-color;
	}

}

.footer{
	color:#777;
	border-top:1px solid #ccc;
	background-color:#ebebeb;
	padding:50px 0;

	a{
		color:#777
	}
	ul{
		li{
			margin-bottom:.5em;
			a{
				// color:#999;
				// text-decoration: underline;
			}
		}
	}

	hr{
		border-top:1px solid #ccc;
	}
	h4{
		color:$headings-color;
	}
}

#sub-footer{
	padding:1em 0;
	background-color:#ccc;
	color:#777;
}