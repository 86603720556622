h1,.h1{
	margin:15px 0;
	min-height:30px;
}

h2,h3{
	margin-top:44px;
	margin-bottom:14px;
}

h4{
	font-weight:bold;

}

hr{
	margin-top:35px;
	margin-bottom:20px;
}

.list-with-icons{
	li{
		padding:.2em 0;

		.glyphicons{
			font-size: 1.2em;
			margin-right: 10px;
			
		}
	}
}

.list-menu{
	padding:1em 0;
	li{
		padding:.2em 0;
		&.list-menu-header{
			text-transform: uppercase;
			font-size: $font-size-small;
			font-weight: 400;
		}
		&.active{
				font-weight: bold;
			a{
				color:$text-color;
			}
		}
	}
}

.error{
	color: $brand-danger;

	
}

p.error{
	&.clear{
		clear:both;
		
	}
	
}



.align-right{
	text-align:right;
}

