
.with-columns{
	h1{
		font-size: $font-size-h2;
		margin: 2rem 0;
	}
	h1+p{
		margin-top:-10px;
	}
	h4{
		//font-weight: 400;
		margin-bottom:1em;
		margin-top: 2em;
		&.margin-top-20{
			@extend .margin-top-20;
		}
		&.margin-top-15{
			@extend .margin-top-15;
		}
	}


	.table{
		tr{
			td{
				
			}
		}
	}
	.sidebar{
		h4{
			margin-top: ($grid-gutter-width/2) + $padding-base-vertical;
			color:$text-color;
			font-weight: 700;
			@media(min-width:$screen-sm-min){
				margin-top: ($grid-gutter-width/2) ;
			}
			button{
				margin-top: -$padding-base-vertical;
			}
		}
	}
}