#modal-content{
	h1{
		//font-size: $font-size-h3;
		margin: $grid-gutter-width/2 0;
	}
	h4{
		//color:#999;
		//font-weight: 400;
		margin-bottom:1em;
		margin-top: 2em;
	}
}


.modal-responsive {
	width: 60vw;
}