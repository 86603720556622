.progress-breadcrumb{
	padding:.4em 0 .4em;
	font-size:18px;
	border-bottom:3px solid #eee;
	@extend .clearfix;
	// margin-bottom:-3px;
	li{

		// display: block;
		clear:left;
		float:left;
		margin-right:.75em;
		&.active{
			border-bottom: 3px solid $brand-info;
			// margin-bottom:-3px;
		}
		@media(min-width:$screen-sm-min){
			display: inline-block;
			float:none;
			
		}
	}
}