// Glyphicons font path
$icon-font-path:        "../fonts/";
$fa-font-path: "../fonts/";
$font-size-base:          16px;

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary: #1b407a;
$brand-success:	#2ab62c;
$brand-info:		#21aee0;
$brand-warning:	#ff851b;
 $brand-warning:	orange;
// $brand-danger:	#ff4136;
$brand-danger: #c0002b;

$brand-light-green: #a8d728;

$brand-inactive:#bbb;

$container-sm:auto;

//$state-warning-bg:$brand-warning;
//$state-warning-text: #fff;
//$state-danger-bg:$brand-danger;
//$state-danger-text: #fff;

$font-size-h1: 28px;

$font-size-h2: 24px;

$font-size-h3: 22px;

$font-size-h4: 19px;

//$headings-font-weight:bold;


$control-font-family: Arial, Helvetica, sans-serif;

// override text emphasis variant mixin

// $font-family-sans-serif:  "Proxima Nova", sans-serif;