

#topbar{
	text-align:right;
	background-color:$brand-primary;
	// display:table;
	// width:100%;
	
	
	#logo-wrap{
		display: table-cell;
		text-align: left;
		height: 55px;
		vertical-align: middle;
	}
	
	#tools{
		text-align:right;
		display: table-cell;
		vertical-align: middle;
		// width:50%;
		.form-group{
			display: inline-block;
			vertical-align: middle;
			margin-bottom:0;

			.btn{
				font-size: $font-size-large;
				color:#fff;
				&:hover, &:focus{
					text-decoration: none;
				}
				@media(min-width:$screen-sm-min){
					font-size: 14px;
					&:hover, &:focus{
						text-decoration: underline;
					}
				}
			}
		}
		select{
			font-size: $font-size-large;
			@media(min-width:$screen-sm-min){
				font-size: 14px;
			}
			border:0;
			background-color:transparent;
			color:#fff;
			transition: none;
			box-shadow:none;
			display: inline-block;
			width:auto;
			
			vertical-align: middle;
			option{
				background-color:#fff;
				color:#333;
			}
		}
	}
}