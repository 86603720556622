.close-button {
  color: $text-color;
}

#cart-btn {
  &.btn-primary {
    color: #fff !important;
  }

  text-transform: none;
}

.btn.btn-link.btn-link-reverse {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
  &:focus{
    outline: none;
  }
}

.btn-table-link {
  display: inline-block;
  margin-top: 0;
  @media(min-width: $screen-sm-min) {
    margin-top: 35px;

  }
}

.badge {

  font-size:0.8em;

  &.badge-success {
    background-color: $brand-success;
  }

  &.badge-danger {
    background-color: $brand-danger;
  }

  &.badge-info {
    background-color: $brand-info;
  }

  &.badge-warning {
    background-color: $brand-warning;
  }
}

