$controls-height:60px;


.controls-2col{
  background-color: #fff;
  padding:0;
  display: flex;
  > div{
    //background-color: pink;
    height: $controls-height;
    align-content:center;
    align-items: center;
    display: flex;
    &:first-child{
      //background-color: palegreen;
      flex: 1 ;

    }

    &:last-child{
      flex: 1;

      justify-content: flex-end;
      //background-color: red;

    }

  }
}

.report-controls{
  display:flex;


  .report-control-labels{
    flex-grow: 1;
    .label{
      display: inline-block;
    }
  }
  .report-control-buttons{
      min-width: 300px;
  }
}


.stickytoolbar {

  width:100%;
  border-top:1px solid #eee;


  &.stick{

    position:fixed;
    padding:0 1em;
    bottom:0;
    left:0;
    border-top:0;
    box-shadow: 0 0 5px #bbb;
    z-index:1050;

  }
}
