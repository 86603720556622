#dnsListTable {
    tr {
        &.not-managed {
            td {
                opacity: 0.5;
                &.record-actions {
                    opacity: 1;
                }
            }

        }
    }
}
.proxy-record {
    position: relative;
    right: -18px;
    font-size: 23px;
    height: 23px;
    padding-top: 1px;
    .fa-cloud {
        color: #ff7900;
        vertical-align: top;
        position: relative;
        z-index: 99;
    }
    .fa-long-arrow-right {
        position: relative;
        vertical-align: top;
        left: -18px;
        color: #888;
    }
    &.key-indicator {
        .fa-cloud {
            vertical-align: middle;
        }
        .fa-long-arrow-right {
            vertical-align: middle;
        }
    }
}