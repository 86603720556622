.cart-detailed{
  width:100%;


  thead{
    background-color:#333;
    color:#fff;
    tr{

      td{

        border:none;
        &:first-child{
          padding-left:30px;
        }
        &:last-child{
          text-align: right;
          padding-right:30px;
        }
      }
      &:last-child{
        td{
          padding-top:0;
          &:last-child{
            font-size:1.3em;
          }
        }
      }
    }
  }
  tfoot{
    tr{

      &:first-child{
        td{
          border-top:none;
        }
      }
      td{
        &:first-child{
          padding-left:30px;
        }
        &:last-child{
          text-align: right;
          padding-right:30px;
          width:180px;
        }
      }

      &:last-child{
        td{
          padding-top:40px;
        }
      }

    }
  }
  tbody{
    tr{
      td{
        padding:10px 15px;
        .category-des{
          font-weight:500;
          display: block;

        }
        .domain{
          display: block;
          font-weight:400;

          .tld{
            color: $brand-success;
          }
        }
        .package{

        }
        .registration-dates{
          @extend .small;
        }
        .inclusive{
          @extend .small;
        }
        &:first-child{
          padding-left:30px;
        }
        &:last-child{
          padding-right:30px;
          text-align:right;
          font-size:1.1em;

        }


      }
    }
  }



}