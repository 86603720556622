$site-width: 1000px;
$max-mobile-width: 799px;
$tablet-min-width: 800px;
$tablet-max-width: 999px;

.flex1 {
  flex: 1; }

.flex100 {
  flex-basis: 100%; }



/* Grid */

.flex-row {
  display: flex;
  flex-wrap: wrap; }
// justify-content: space-between



// .col
//   flex: 1

.col {
  width: 100%;
  box-sizing: border-box; }
// &._25
//   flex-basis: 100%
// &._33
//   flex-basis: 100%
// &._50
//   flex-basis: 100%
// &._66
//   flex-basis: 100%
// &._75
//   flex-basis: 100%
// &._w100
//   flex-basis: 100%

@media screen and (min-width: $tablet-min-width) {
  .flex-row {
    flex-wrap: nowrap; }
  .col {
    &._11 {
      width: 11%; }

    &._15 {
      width: 15%; }

    &._16 {
      width: 16%; }
    &._24 {
      width: 24%; }

    &._25 {
      width: 25%; }

    &._33 {
      width: 33%; }

    &._50 {
      width: 50%; }

    &._66 {
      width: 66%; }

    &._75 {
      width: 75%; } } }

.flex {
  display: flex; }

.flex-row {
  flex-direction: row; }


.flexrr {
  flex-direction: row-reverse; }

.flex-col {
  flex-direction: column; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-wrap-sm {
  flex-wrap: wrap;
  @media screen and (min-width: $tablet-min-width) {
    flex-wrap: nowrap; } }

.grid {
  max-width: $site-width;
  margin: 0 auto; }

.align-start {
  align-items: flex-start; }

.align-center {
  align-items: center; }

.align-end {
  align-items: flex-end; }

.align-around {
  align-content: space-around; }

.justify-between {
  justify-content: space-between; }

.justify-start {
  justify-content: flex-start; }

.justify-center {
  justify-content: center; }



.justify-end {
  justify-content: flex-end; }



.justify-start-sm-center {
  justify-content: center;
  @media screen and (min-width: $tablet-min-width) {
    justify-content: flex-start; } }

.justify-around {
  justify-content: space-around; }


.flex1 {
  flex: 1; }


div.flexrow-error {
  margin-top: -10px;
  margin-bottom: 1rem;
  color: red; }
