
.sortable-panel {

  position: relative;
  background-color: #f7f7f7;
  margin-top: 1.0em;
  border: 1px solid #ccc;

  .list-group {
    height: 250px;
    margin-bottom: 0;
    z-index: -1;
    overflow: auto;


    .list-group-item {
      border-left: none;
      border-right: none;
      width: 100%;
      cursor: move;
      .glyphicon {
        font-size: 20px;
        float: right;
        color: #bbb;
        cursor: pointer;
        &:hover, &:focus {
          text-decoration: none;
        }
      }
      .label-default {
        background-color: #bbb;
        float: right;

      }
      &.added {
        opacity: .6;
        background-color: #efefef;
        &:hover {
          .label-default {
            display: block;
            background-color: #aaa;
          }
          opacity: 1;
        }
        &:focus {
          .label-default {
            display: none;
          }
        }

      }
      &:hover {
        background-color: #efefef;
        .glyphicon {
          color: #aaa;
        }
      }
    }
  }

  &.with-search {
    .list-group {
      height: 142px;
      margin-top: 48px;
    }
  }

  .search-box {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;

    .form-group {
      margin-bottom: 0;
      padding: 0 .5em .5em;
      margin-top: .5em;
      border-bottom: 1px solid #ccc;

    }
  }

  input{
    background-color: #f7f7f7;
    -webkit-border-radius:0;
    -moz-border-radius:0;
    border-radius:0;
  }

}

.sortable-pair {

  > div {

    .sortable-panel {
      margin-left: .5em;
    }

    &:first-child {

      .sortable-panel {
        //        background-color: red;
        margin-left: 0;
        margin-right: .5em;

      }
    }
  }
}



