$max-mobile-width: 799px;
$tablet-width: 800px;
$site-width: 1000px;
$whiteish: #f8f8f8;


.whiteishbk {
  background-color: $whiteish; }

.actionpage {

  width: 100%;
  margin: 0 auto;
  @media screen and (min-width: $site-width) {
    width: $site-width; }
  &.narrow {
    @media screen and (min-width: $tablet-width) {
      width: $tablet-width; } } }

.actionitem {
  display: flex;
  @media screen and (max-width: $max-mobile-width) {
    flex-direction: column; }

  .actioninfo {
    background-color: white;

    width: 100%;
    @media screen and (min-width: $tablet-width) {
      width: 50%; } }
  .actions {
    background-color: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media screen and (min-width: $tablet-width) {
      width: 50%; } }

  &.sixtyforty {
    .actioninfo {
      @media screen and (min-width: $tablet-width) {
        width: 60%; } }
    .actions {
      @media screen and (min-width: $tablet-width) {
        width: 40%; } } } }

.actionnotice {
  background-color: white;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef; }
