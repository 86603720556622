#home-menu{


	&.drawer{
		z-index: 1100;
	}


	.drawer-title{
		text-transform: uppercase;
		text-align: center;
	}
	.drawer-nav li span.inactive {
	  color: $brand-inactive;
	  border-radius: 4px;
	  position: relative;
	  display: block;
	  padding: 10px 15px;
	}


}