#transfer-results {

  table-layout: fixed;
  width: 100%;

  thead {
    tr {
      th {
        &:first-child {
          width: 35px;
        }
        &:nth-child(2) {

        }
        &:nth-child(3) {

          display: none;
          @media (min-width: $screen-sm-min) {
            display: table-cell;
            width: 35%;
          }
          @media (min-width: $screen-md-min) {
            display: table-cell;
            width: 25%;
          }
        }
        &:last-child {
          width: 35%;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        vertical-align: middle;
        &:nth-child(3) {
          display: none;
          @media (min-width: $screen-sm-min) {
            display: table-cell;

          }
        }
      }
    }
  }

}

#transfer-processed-results {

  table-layout: fixed;
  width: 100%;

  thead {
    tr {
      th {
        &:last-child {
          width: 60%;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &:last-child {
          width: 60%;
        }
      }
    }
  }
}