
#nav-main{
	clear:both;
	
	margin-bottom:15px;
	
	
	color:#333;
	background-color:#fff;
	font-weight:500;
	// font-family:$control-font-family;
	text-transform:uppercase;
	font-size:20px;
	@media(min-width:$screen-sm-min){
		font-size:17px;
	}
	a{
		word-spacing: 0;
		color:#333;
		&.btn-warning{
			color:#fff;
		}
	}

	&.fixed{
		width:100%;
		z-index: 50;
		position:fixed;
		top:0;
		left:0;
		background-color:#eee;
	}

	.separator{
		color:#aaa;
		padding:6px 0;
	}

	.bcrumb{
		display: table-cell;
		vertical-align: middle;
		height:63px;
		.btn{
			
			&.btn-link{
				color:#333;
				text-transform: uppercase;
				font-weight: 500;
				padding:6px;
				font-size:20px;
				@media(min-width:$screen-sm-min){
					font-size: $font-size-base;

				}
				&.end-leaf{
					font-size: $font-size-small;
					@media(min-width:$screen-sm-min){
						font-size: $font-size-base;
					}
				}

			}
			
		}
	}

	.ctas{
		display: table-cell;
		vertical-align: middle;
		text-align: right;
		word-spacing: 0;
		
		@media(min-width:$screen-sm-min){
			word-spacing: 1em;
		}
		button{
			
			&.btn-link{
				color:$text-color;
				text-transform: uppercase;
				font-weight: 500;
				
				font-size:20px;
				@media(min-width:$screen-sm-min){
					font-size: $font-size-base;
				}
			}
			
		}
	}
	
}
