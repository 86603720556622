.ooawizard {
    display: none; }
.ooawizard-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: none;
    z-index: 999999999;
    .ooawizard-modal {
        width: 90%;
        max-width: 900px;
        margin: 100px auto;
        height: 600px;
        background-color: grey;
        position: relative;
        .ooawizard-modal-content {
            position: relative;
            height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            .ooawizard-modal-header {
                display: flex;
                height: 55px;
                background-color: #555555;
                color: white;
                padding: 8px 20px;
                h4 {
                    flex: 1;
                    color: white; }
                a {
                    border-bottom: none; }
                i {
                    font-size: 1.6em;
                    line-height: 1.6em;
                    color: white; } }
            .ooawizard-modal-body {
                position: relative;
                height: 100%;
                background-color: white;
                .slide {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    overflow: scroll; } }
            .ooawizard-modal-footer {
                display: flex;
                background-color: #f8f8f8;
                height: 55px;
                padding: 5px;
                align-items: center;
                justify-content: space-between;
                button {
                    margin: 7px 5px; } } } } }
