

.right-last {
  tr {
    td, th {
      &:last-child {
        text-align: right;
      }
    }
  }
}

.right-last-td {
  tr {
    td {
      &:last-child {
        text-align: right;
      }
    }
  }
}

.left-first-td {
  tr {
    td, th {
      text-align: right;

      &:first-child {
        text-align: left;
      }
    }
  }
}

.table {
  &.table-no-border-top {
    border: 0;
    tbody {
      border-top: 0;
      tr {
        border-top: 0;
        &:first-child {
          td, th {
            border-top: 0;
          }
        }
      }
    }
  }
}

.edge-last-td {
  tr {
    td {
      &:last-child {
        width: 40%;
      }
    }
  }
}

.truncate, .truncate-300 {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  @media(min-width: $screen-sm-min) {
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.truncate-400 {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: $screen-sm-min) {
    width: 400px;
    white-space: nowrap;
  }
}

.truncate-350 {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: $screen-sm-min) {
    width: 350px;
    white-space: nowrap;
  }
}

.truncate-200 {
  width: 200px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-120 {
  width: 200px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editable-cell {
  border: 1px dashed #ccc;
  padding: 2px;
  &:hover {
    border: 1px solid black;
    cursor: pointer;
  }
}

.ooafootable {
  th {
    &[data-sort-key] {
      // background-color:red;
      .glyphicons {
        @extend .pull-right;
        margin-left: .25em;
        &.glyphicons-sorting {
          color: #bbb;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.footable-odd {
  background-color: #fff;
}

.footable-detail-show {
  background-color: #eee;
  + .footable-row-detail {
    background-color: #f7f7f7;
  }
}

.footable-row-detail-cell {
  border-top: none !important;
  // background-color:lighten(yellow,45%);
}

.table-controls {
  margin-bottom: 1em;
  // background-color:red;

  .col-sm-7, .col-sm-5 {
    // background-color:#f7f7f7;

    @extend .clearfix;
    @media (min-width: $screen-sm-min) {
      padding-top: .5em;
    }

  }

  .col-sm-7 {
    .table-controls-cell {
      @extend .clearfix;
      background-color: #f7f7f7;
      padding-right: 15px;
      padding-left: 15px;
      padding-top: .5em;
      padding-bottom: .5em;
      margin-left: 0;
      @media (min-width: $screen-sm-min) {
        margin-left: -15px;
      }
    }
  }

  .col-sm-5 {
    .table-controls-cell {
      @extend .clearfix;
      background-color: #f7f7f7;
      padding-left: 15px;
      padding-top: .5em;
      padding-bottom: .5em;
      margin-right: 0;
      @media (min-width: $screen-sm-min) {
        margin-right: -15px;
      }
    }
  }

  .col-sm-3 {
    .table-controls-cell {
      @extend .clearfix;
      background-color: #f7f7f7;
      padding-left: 15px;
      padding-top: .5em;
      padding-bottom: .5em;
      margin-right: 0;
      @media (min-width: $screen-sm-min) {
        margin-right: -15px;
      }
    }
  }

  .search-form {
    float: none;
    @media (min-width: $screen-sm-min) {
      @extend .form-inline;
      float: right;

    }
  }

  .numbertoshow {
    select.form-control {
      display: inline-block;
      width: auto;
    }
  }
}

.table-controls-alt {
  @extend .clearfix;
  background-color: #f7f7f7;
  padding-left: 15px;
  padding-top: .5em;
  padding-bottom: .5em;
  margin-right: 0;
}

.table-controls-footer {
  margin-top: 15px;
  padding-top: 15px;
  .pagination {
    margin: 0;
  }
  .showing-indicator {
    height: 41px;
    line-height: 41px;
  }
}

.hover-property {
  .hover-target {
    display: none;

  }
  &:hover {
    .hover-target {
      display: inline;
    }
  }
  &.hover-warning {
    .hover-target {
      color: $brand-warning;
    }
  }
}

.ooafilter {
  .loader {
    display:none;
  }
  &.loading {
    .loader {
      width:100%;
      text-align:center;
      display:inline-block;
    }
  }
}