.popover.spellsuggest, .ooarichtext .popover {
    width: auto;
    .popover-title {
        display: none; }
    .popover-content {
        padding: 10px;
        ul {
            list-style: none;
            margin-left: 0;
            a {
                cursor: pointer;
                &:hover {
                    text-decoration: none; } } } } }
