.block-items {
  background-color: white;
  margin-top: 5px;
  width: 300px;
  > span {
    display: flex;
    min-height: 50px;
    justify-content: space-between;
    align-items: center;
    border: 1px #aaa solid;
    padding: 0 0.5em;
    &:hover {
      cursor: move; }
    .disclosure-icon {
      display: block;
      padding: 0.5em;
      &:hover {
        cursor: pointer; } } }
  .item-properties {
    border: 1px solid #aaaaaa;
    border-top: 0;
    padding: 1em;
    > div {
      margin-bottom: 1em; } } }

.sub-items {
  margin-left: 20px;
  .block-items {
    width: 280px; }
  .items-ghost {
    min-height: 50px;
    border: dashed 1px black;
    width: 280px; }

  .sub-items {
    .block-items {
      width: 260px; }

    .sub-items {
      .block-items {
        width: 240px; }

      .sub-items {
        .block-items {
          width: 220px; } } } } }
