
#netistrar-search {
  margin-top: 1.5em;
}

#search-results-col {
  min-height: auto;
  //padding-left:0;
  //padding-right:0;
  h1 {
    // margin:1em 0;
    // padding-top:1em;
    span {
      color: $brand-success;
    }
  }
  @media (min-width: $screen-md-min) {
    min-height: 800px;
  }
}

#netistrar-search-results {
  table-layout: fixed;

  tbody {
    tr.result-row {
      td {
        vertical-align: middle;
        border-top-style: dashed;
        &:first-child {
          padding-left: 1em;
          font-size: 20px;
          color: $brand-success;
          color: #aaa;
          width: 10%;

        }
        &:nth-child(2) {
          text-transform: uppercase;
          font-size: 18px;
          color: $brand-success;
          color: #aaa;
          font-weight: 600;
          line-height: 1.3;
          width: 20%;
          span, .more-information-btn {
            text-transform: none;
            font-size: 14px;
            font-weight: 300;
          }
          padding-left: 15px;
          @media (min-width: $screen-sm-min) {
            padding-left: 0;
          }

        }
        &:nth-child(3) {
          width: 40%;
        }
        &:nth-child(4) {
          width: 20%;
        }
        &:last-child {
          width: 10%;
        }
        &.control {
          // display: table-cell;
          @media(min-width: $screen-sm-min) {
            display: none;
          }
        }
        .add-to-cart {
          &.btn {
            @media(min-width: $screen-sm-min) {
              display: inline-block;
              width: auto;
            }
          }
        }
        .tld {
          color: $brand-success;
        }
        .label {
          margin-bottom: 5px;
          display: inline-block;
          .glyphicons {
            vertical-align: baseline;
          }

        }
        &.sample-price-cell {
          text-align: right;
          padding-right: 10px;
          font-size: 11px;
          text-transform: uppercase;
          .sample-price {
            font-size: 1.8em;
          }
        }
      }
      &.child {
        display: table-row;
        background-color: #f8f8f8;
        @media(min-width: $screen-sm-min) {
          display: none;
        }
        ul {
          display: block;

          li {
            display: block;
            select {
              display: inline-block;
              &.form-control {
                width: auto;
              }
              option {
                display: inline-block;
              }
            }
            .add-to-cart {
              &.btn {
                display: block;
                width: 100%;

              }

            }
          }
        }

      }
      &.available {
        td {
          &:first-child {
            color: $brand-success;
          }
          &:nth-child(2) {
            color: $brand-success;
          }
          .tld-appended {
            color: $brand-success;
          }
        }
      }

      &.expired {
        td {
          .tld-appended {
            color: $brand-success;
          }

          &.qty {
            text-align: center;
            font-weight: bold;
          }
        }
      }

      &.renewal, &.preorder {
        td {
          &:first-child {
            color: $brand-primary;
          }
          &:nth-child(2) {
            color: $brand-primary;
          }
          .tld-appended {
            color: $brand-primary;
          }
        }
      }
      &.restricted, &.pending {
        td {
          &:first-child {
            color: $brand-warning;
          }
          &:nth-child(2) {
            color: $brand-warning;
          }
          &:nth-child(3) {
            .notice {
              a {
                color: $brand-warning;
              }
            }
          }
          .tld-appended {
            color: $brand-warning;
          }
        }
      }
      &.more-information {
        .alert {
          text-align: left;
        }
        td {
          &:first-child {
            font-size: $font-size-base;
          }
        }
      }
    }
  }
}

#search-box {
  // border-top:4px solid $brand-primary;
  text-align: center;
  padding: 1em;
}