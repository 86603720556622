.scroll-top-wrapper {
  position: fixed;
  visibility: hidden;
  overflow: hidden;
  text-align: center;
  z-index: 40;
  background-color:#555;
  color:#fff;
  width: 47px;
  height: 36px;
  line-height: 36px;
  right: 2%;
  top: 60%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;

  .glyphicons{
    line-height: inherit;
    font-size: 22px;
    // opacity:0.8;
  }
}
.scroll-top-wrapper:hover {
  background-color: #333;

}
.scroll-top-wrapper.show {
  visibility:visible;
  cursor:pointer;
  opacity: 0.9;
}
