.twitter-typeahead {

    .tt-query, .tt-hint {
        margin-bottom: 0; }

    .tt-dropdown-menu {
        min-width: 160px;
        margin-top: 2px;
        padding: 5px 0;
        background-color: #fff;
        border: 1px solid #ccc;
        border: 1px solid rgba(0, 0, 0, .2);
        *border-right-width: 2px;
        *border-bottom-width: 2px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
        -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
        box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
        -webkit-background-clip: padding-box;
        -moz-background-clip: padding;
        background-clip: padding-box;
        max-height: 260px;
        overflow-y: scroll;
        width: 100%;
        .tt-suggestion {
            display: block;
            padding: 3px 20px;
            p {
                margin: 0; }

            &.tt-cursor {
                color: #fff;
                background-color: #0081c2;
                background-image: -moz-linear-gradient(top, #0088cc, #0077b3);
                background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
                background-image: -webkit-linear-gradient(top, #0088cc, #0077b3);
                background-image: -o-linear-gradient(top, #0088cc, #0077b3);
                background-image: linear-gradient(to bottom, #0088cc, #0077b3);
                background-repeat: repeat-x;
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0);
                a {
                    color: #fff; } } }

        .address-country-picker {
            border-radius-topleft: 0;
            border-radius-topright: 0;
            border-top: 1px solid #eee;
            padding: 5px;
            text-align: right; } } }
