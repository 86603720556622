

#product-prices{
  thead{
    tr{
      th{
        text-align: right;

        &:first-child{
          text-align: left;
        }
      }
    }
  }
  tfoot{
    
  }
  tbody{
    tr{
      td{
        text-align: right;

        &:first-child{
          text-align: left;
        }
      }
    }
  }
}