.ooarichtext-toolbar {
    .well {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
        .btn-group {
            .btn {
                font-size: 11pt !important;
                padding: 5px 10px;
                font-size: 12px;
                line-height: 1.5;
                border-radius: 3px;
                background-image: none;
                border-color: #F5F5F5;
                -webkit-box-shadow: none;
                box-shadow: none;
                &:hover {
                    font-size: 11pt !important;
                    display: inline-block;
                    padding: 6px 12px;
                    margin-bottom: 0;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 1.428571429;
                    text-align: center;
                    white-space: nowrap;
                    vertical-align: middle;
                    cursor: pointer;
                    border: 1px solid transparent;
                    border-radius: 4px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    -o-user-select: none;
                    user-select: none;
                    padding: 5px 10px;
                    font-size: 12px;
                    line-height: 1.5;
                    border-radius: 3px; }
                &.active {
                    border: 1px solid #cccccc;
                    outline: 0;
                    -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
                    -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
                    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); } } } } }

.ooarichtext {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
    &.active {
        background-color: white;
        border: 1px #e5e5e5 solid;
        border-top: none;
        padding: 5px;
        font-family: georgia; }
    .autocomplete-source {
        display: none; } }

.wordcount-wrapper {
    border: 1px solid #e3e3e3;
    border-top: none;
    background-color: #f5f5f5;
    padding-left: 5px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    font-size: 10pt; }
