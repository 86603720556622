.dashboard-icons{
	a, span.inactive{
		margin-bottom: 2em;
		display: block;
		text-align:center;
		font-size: 14px;
		color:$text-color;
		
		padding:1em;
		height:150px;
		

		.title{
			font-size: $font-size-base;
			font-weight:500;
			display: block;
			margin:.75em 0 .25em;
		}

		&:hover{
			background-color: #eee;
			text-decoration: none;
		}

		img{
			display: inline-block;
		}

		.glyphicons{
			height:39px;
			font-size: 37px;
		}

		.fa{
			height:39px;
			font-size: 37px;
		}



	}

	span.inactive{
		&:hover{
			background-color:transparent;
		}
		.glyphicons,.glyphicon{
			color:$brand-inactive;
		}
		.fa {
			color:$brand-inactive;
		}
		color:$brand-inactive;
		img{
			opacity:0.7;
		}
	}
}

#dashboard{
	h4{
		color:$headings-color;
	}
}