.ooaeditabletable {

  .table-row {
    display: flex;
    &:last-child {
      .table-cell {
        border-bottom: 1px solid #ccc; } }
    &:first-child {
      .table-cell {
        border-top: 1px solid #ccc; } }
    &.table-header {
      font-weight: bold;
      .table-cell {
        border-top: 1px solid #ccc; } }

    .table-cell {
      width: 180px;
      border-right: 1px solid #ccc;
      border-left: 0;
      border-bottom: 1px solid #ccc;
      &.highlight {
        input {
          background-color: lightyellow; } }
      &:first-child {
        border-left: 1px solid #ccc; }
      input {
        border: 0;
        border-collapse: collapse;
        padding: .3rem .5rem;
        width: 100%;
        &.highlight-select {
          background-color: #eeeeee; } } } } }


