body{
	background-color:#f8f8f8;
}

h1,.h1{
	font-weight:500;
	margin:1.5em 0 1.3em;
	font-size:32px;
	@media(min-width:$screen-sm-min){
		font-size:$font-size-h1;
	}
}



sup{
	.label{
		border-radius:1em;
	}
}

a{
	>small{
		font-weight: 400;
	}
}

.table-table{
	display:table;
	width:100%;
}

.table-row{
	display:table-row;
	width:100%;
}

.table-cell{
	display:table-cell;
}




&[class*="panel-"]{
	color:#fff;
	&[class*="info"]{
		background-color:$brand-info;
	}
	&[class*="primary"]{
			background-color:$brand-primary;
		}
	&[class*="warning"]{
			background-color:$brand-warning;
		}
	&[class*="danger"]{
			background-color:$brand-danger;
	}
	
}




.glyphicons{
	// line-height:1.42857;
	// vertical-align:middle;
	&:before{
		vertical-align: middle;
	}
}

.label-as-badge {
    border-radius: 1em;
}

$white:#fff;
$red:red;


[class*="bg-"]{
	&[class*="white"]{
		background-color:$white;
	}
	&[class*="red"]{
		background-color:$red;
	}
	&[class*="info"]{
		background-color:$brand-info;
		color:#fff;
	}
	&[class*="primary"]{
		background-color:$brand-primary;
	}
	&[class*="pale-grey"]{
		background-color:#f1f1f1;
	}
	&[class*="success"]{
		background-color:$brand-success;
		color:#fff;
	}
}

[class*="border-"]{

	&[class*="top"]{
		border-top:1px solid #eee;
	}
	&[class*="right"]{
		border-right:1px solid #eee;
	}
	&[class*="left"]{
		border-left:1px solid #eee;
	}
	&[class*="bottom"]{
		border-bottom:1px solid #eee;
		&.width-2{
			border-bottom-width:2px;
		}
	}
	
}



.overlay {
	display:none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1050;
  background-color: rgba(0,0,0,0.5);
}

.text-muted{
	&.rev{
		color:#eee;
	}

}

.text-pale{
	color:$brand-inactive;
}

.text-warning{
	color:$brand-warning;
}

.text-danger{
	color:$brand-danger;
}

.text-success{
	color:$brand-success;
}

.text-primary{
	color:$brand-primary;
}

.text-info{
	color:$brand-info;
}

.text-body{
	color: $text-color;
}

.text-bold{
	font-weight:bold;
}

.text-normal{
	font-weight:normal;
}

.text-thin{
	font-weight:200;
}

.toggle-show{
	display:none;
}

.datepicker{
	td{
		&:hover{
			cursor: pointer;
		}
	}
}

.select2-container{
	.select2-selection--single {
		height: 36px !important;
	}

}

.select2-container--default{
	.select2-selection__rendered {
		line-height: 36px !important;
	}
	.select2-selection--single{
		.select2-selection__arrow{
			height:36px !important;
		}
	}

}

.intl-tel-input {width: 100%;}


.tt-dataset-dataset{
	a,li,div{
		&:hover{
			cursor: pointer !important;
		}
	}
}

.existingvalue {
	background-color: #f5f5f5;
	border: 1px solid #d9d9d9;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	padding: 3px 4px;
	margin-right: 3px;
	margin-bottom: 3px;
	.remove-item {
		font-size: 10px;
		opacity: 0.5;
	}
}

