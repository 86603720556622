
.sidebar {
  &.sidebar-left {
    border-right: 1px solid #ddd;
    margin-right: -15px;
    padding-right: 15px;
    // margin-top: -15px;

  }

  &.sidebar-right {
    border-left: 1px solid #ddd;
    margin-left: -15px;
    padding-left: 15px;
    //padding-right:15px;
  }

  h4 {

    margin-top: 0;
    padding-top: 15px;
    text-transform: uppercase;

  }

  h5 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
  }

  .table {
    tr {
      td {
        border-top: 0;
        &:first-child {
          text-align: right;
          width: 12%;
        }
        //&:nth-child(2) {
        //  text-align: right;
        //  width: 12%;
        //}

      }

      span.fa {
        &:before {
          font-family: "FontAwesome";
          color: $brand-success;
          content: "\f00c";
        }
      }
      &.success-summary {
        span.fa {
          &:before {
            content: "\f00c";
            color: $brand-success;
          }
        }
      }
      &.warning-summary {
        span.fa {
          &:before {
            content: "\f071";
            color: $brand-warning;
          }
        }
      }
      &.danger-summary {
        span.fa {
          &:before {
            content: "\f071";
            color: $brand-danger;
          }
        }
      }
    }
    &.table-activity {
      tr {
        td {
          text-align: left;
        }
        td {
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  .list-group {

  }

}

.sidebar-submenu {
  border-right: 0;
  //@media(min-width: $screen-sm-min) {
  //  border-right: 1px solid #eee;
  //}
  hr {
    margin: 0;
  }
  ul {
    margin-bottom: 0;
  }

}

.filters {
  margin-left: 0;
  margin-top: 2em;
  @media (min-width: $screen-md-min) {
    margin-top: 0;
  }

  h4 {
    margin: 1em 0 1em 10px;
    text-transform: uppercase;
    font-weight: 700;
  }
  h5 {
    margin-left: 10px;
  }
  font-size: 14px;

  p {
    margin-bottom: 0;
  }
  a {
    display: block;
    color: #666;
    padding: 10px 10px;
    //margin-left:-15px;
    &:hover, &:focus {
      text-decoration: none;
    }
    &.selected {
      background-color: #ddd;
      font-weight: 500;
      color: #333;
    }
  }
}






