.simple-progress-bar{
	//border-top:1px solid #eee;
	border-bottom:2px solid #ddd;
	margin:40px 0;
	//padding:8px 0;
	@extend .clearfix;
	li{
		float:left;
		display: block;
		width:100%;
		margin-right:1em;
		padding:8px 15px 6px;
		border-bottom:3px solid transparent;
		&.active{
			border-bottom:3px solid $brand-info;
		}
		@media (min-width:$screen-sm-min) {
			display:inline-block;
			padding:0 15px 6px;
			width:auto;
		}
	}

}

.progress-list{
	li{
		margin-bottom:.5em;
	}
}