
.panely-container {
  display: flex;
  flex-wrap: wrap; }


.panely {
  background-color: #eee;
  width: 100%;
  margin: 0 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
  padding: 1rem;

  @media screen and (min-width: $tablet-min-width) and (max-width: $site-width) {
    width: 32%;
    margin: 1%;
    &:nth-of-type(3n) {
      margin-right: 0; }
    &:nth-of-type(3n+1) {
      margin-left: 0; } }


  @media screen and (min-width: $site-width) {

    width: 24%;
    margin: .65%;

    &:nth-of-type(4n) {
      margin-right: 0; }
    &:nth-of-type(4n+1) {
      margin-left: 0; } }

  h5 {
    font-weight: bold; }

  &.aslink {
    justify-content: center;
    align-items: center;
    color: #aaa;
    position: relative;
    h1 {
      margin: 0;
      margin-bottom: 1rem;
      font-size: 40px; }
    h3 {
      margin: 0; }

    .panelylink {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      &:hover {
        opacity: .8; } } }


  .addresstools {
    @extend .small;
    display: flex;
    justify-content: space-between;
    align-items: center; } }

