label{
	small{
		font-weight:normal;
	}
}

#form-box{
	// padding-bottom:1em;
}

.form-horizontal{
	.control-label{
		text-align:left;
		@media(min-width:$screen-sm-min){
			text-align:right;	
		}
	}
}

.extra-controls{
	padding:.5em;
	border:1px solid $brand-light-green;
	margin-bottom:1em;
}

.form-header{
	margin:2em 0 1em;
	color:$brand-light-green;
}

.checkbox-box{
	// margin-top:5px !important;
	// margin-bottom:5px;
	label{
		background-color: #ebebeb;
		// background-color: lighten($brand-light-green,45%);
		padding:1em 2em;

	}
}

.radio-inline-box{
	padding-top:7px;
	display:inline-block;
	margin-right:5px;
	label{

		background-color: #ebebeb;
		// background-color: lighten($brand-light-green,45%);
		padding:1em 2em 1em 2em;

	}
}

#password-checklist{
	.glyphicons-remove-2{
		color:#aaa;
	}
	.spacer{
		margin:.5em 0;
	}
}

.form-control{
	&.featured{
		border:1px solid $brand-primary;
	}


}

.form-control::-webkit-input-placeholder{
	font-weight:200;

}


.form-control::-moz-placeholder{
	font-weight:200;

}


.form-control:-moz-placeholder{
	font-weight:200;

}


.form-control:-ms-input-placeholder{
	font-weight:200;

}

.form-group{
	&.left-labels{
		label{
			text-align: left;
		}
	}
}

.disabled-labels {
	h3, h5, label {
		color: #aaa;
	}
}

.address-search-result{
	@extend .well;
	min-height:200px;
}
