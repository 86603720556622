@import "/ooatoolkit/bower_components/select2/dist/css/select2.css";
@import "/ooatoolkit/bower_components/footable/css/footable.core.css";
@import "/ooatoolkit/bower_components/intl-tel-input/build/css/intlTelInput.css";
@import "/ooatoolkit/bower_components/dropzone/dist/dropzone.css";
@import "/ooatoolkit/thirdparty/bootstrap-datepicker/css/datepicker3.css";
@import "/ooatoolkit/thirdparty/datatables-bootstrap/dataTables.bootstrap.css";
@import "/ooatoolkit/thirdparty/redactor/redactor.min.css";
@import "/ooatoolkit/thirdparty/redactor/plugins/alignment.css";
@import "/ooatoolkit/thirdparty/simplemde/simplemde.min.css";
@import "/ooatoolkit/thirdparty/highlight/styles/default.css";
@import "/ooatoolkit/thirdparty/spectrum/spectrum.css";

@import "popover";
@import "ooarichtext";
@import "typeahead";
@import "ooaimagecapture";
@import "ooadiallingcodedropdown";
@import "ooaresponsivetable";
@import "ooawizard";
@import "ooaeditabletable";

.well-mini {
    padding: 5px; }

.icon-adobe-pdf {
    color: darkred; }

.form-horizontal .checkbox input.checkbox {
    margin-top: 4px; }

.table-controls {
    .search-form {
        float: right; } }

.tp-input {
    width: 80px;
    border: 1px solid #ccc;
    background-color: white;
    height: 34px;
    padding: 6px 0px 6px 10px;
    font-size: 14px;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    input {
        border: none; } }

.auto-complete-suggestion-li {
    &.active {
        background-color: blue;
        color: white; } }
